.container {
  padding: 1.5rem;
  display: flex;
}

.logo {
  display: flex;
  align-items: center;
  flex: 1;
  gap: 1rem;
}

.logo > img {
  width: 2.5rem;
  height: 2.5em;
}

.logo > span {
  font-weight: 600;
}

.right {
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: center;
}

.menu {
  display: flex;
  list-style: none;
  gap: 2rem;
  font-weight: 500;
}

.menu > li:hover {
  color: #fe956f;
  cursor: pointer;
}

.search {
  width: 7rem;
  outline: none;
  border: none;
  height: 30%;
  border-radius: 5px;
  padding: 0.5rem;
}

.cart {
  width: 1.5em;
  height: 1.5em;
  cursor: pointer;
}

.bars {
  display: none;
}

@media screen and (max-width: 856px) {
  .search {
    display: none;
  }
  .right {
    gap: 1rem;
  }
  .menu{
    display: flex;
  }
}

@media screen and (max-width: 640px) {
  .right {
    position: absolute;
    right: 1rem;
    z-index: 9999;
    background: white;
    color: black;
    border-radius: 5px;
    flex-direction: column;
    padding: 1rem;
    align-items: flex-end;
    text-align: right;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  }

  .menu {
    flex-direction: column;
    margin-top: 0;
  }

  .bars {
    display: block;
    font-size: 1.5em;
  }

  .search {
    display: none;
  }
}
